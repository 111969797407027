<template>
  <div class="yt-main exam_detail--style">
    <!--   面包屑   -->
    <el-breadcrumb class="yt-breadcrumb">
      <el-breadcrumb-item>学习管理</el-breadcrumb-item>
      <el-breadcrumb-item to="/manage/study/course/detail">课程管理</el-breadcrumb-item>
      <el-breadcrumb-item to="/manage/study/practice/list">查看练习</el-breadcrumb-item>
      <el-breadcrumb-item>练习详情</el-breadcrumb-item>
    </el-breadcrumb>

    <!--   考试信息   -->
    <div class="yt-container yt-flex-layout">
      <div class="exam_left">
        <div class="yt-flex-layout exam_header">
          <div class="yt-flex-layout">
            <h3 class="exam_name">{{ paperName }}</h3>
            <div v-if="referenceTimes > 1" class="yt-flex-layout">
              <span
                :class="rIndex === selectRow ? 'selected_exam--info' : ''"
                @click="getOneExamInfo(rIndex)"
                class="span_unselected span_default yt-flex-layout"
                v-for="(r, rIndex) in referenceTimes"
                :key="rIndex"
              >
                {{ `第${getExamNum(rIndex + 1)}次` }}
                <img v-show="rIndex === selectRow" src="@/assets/examDetail/select_exam-detail.png" width="15" height="15" alt="" />
              </span>
            </div>
          </div>
          <div>
            <el-checkbox @change="filterQuestion(0)" v-model="isWrong">只看错题</el-checkbox>
          </div>
        </div>

        <div class="exam_info yt-flex-layout">
          <div class="info_one--style yt-flex-layout" v-for="(l, lIndex) in infoList" :key="lIndex">
            <YTIcon :href="`#icon-${l.icon}`" :style="{ color: l.color }" />
            <span style="margin-right: 10px">{{ l.name }}:</span>
            <span v-if="l.key.includes('Time')">{{ $formatTime(l.value, 'yyyy-MM-dd') }}</span>
            <span v-else>{{ l.value }}{{ l.company }}</span>
          </div>
        </div>

        <div class="exam_question">
          <div class="big_question--type" v-for="(q, qIndex) in questionList" :key="qIndex">
            <p class="big-title yt-flex-layout" v-show="q.questions.length">
              {{ q.name }}
              <span>{{ q.questions.length }}</span>
              题，共
              <span>{{ q.totalPoints }}</span>
              分
            </p>

            <!--      展示各类型的题目        -->
            <div
              class="question_info"
              @mouseenter="selectNumById = vo.questionId"
              :id="`num-${vo.questionId}`"
              v-for="(vo, vIndex) in q.questions"
              :key="vIndex"
            >
              <div class="stem yt-flex-layout">
                <span>{{ vIndex + 1 }}、</span>
                <div v-html="cleanWord(vo.questionDetail.stem)"></div>
              </div>
              <!--      判断题        -->
              <div style="margin-top: 12px" v-if="vo.questionDetail.questionType === 0">
                <el-radio-group v-model="vo.questionDetail.answer">
                  <el-radio :label="'正确'"></el-radio>
                  <el-radio :label="'错误'"></el-radio>
                </el-radio-group>
              </div>
              <!--      单选题 || 多选题       -->
              <div style="margin-top: 12px" v-if="vo.questionDetail.questionType === 1 || vo.questionDetail.questionType === 2">
                <div class="yt-flex-layout" v-for="(op, oIndex) in vo.questionDetail.options" :key="oIndex">
                  <span>{{ $getChoiceIndex(oIndex) }}、</span>
                  <span v-html="op.content" />
                </div>
              </div>
              <!--      考生答案及题目答案  resultStatus为0是考生答案正确      -->
              <div style="margin-top: 20px" v-if="vo.questionDetail.doRecord">
                <div
                  class="analysis_style"
                  :style="{ color: !vo.questionDetail.doRecord.doStatus && vo.questionDetail.doRecord.answer ? '#06C281' : '#ea4e18' }"
                >
                  考生答案:<span style="margin-left: 3px" v-if="vo.questionDetail.questionType !== 5 || !vo.questionDetail.doRecord.answer">
                    <span>{{ vo.questionDetail.doRecord.answer || '暂未作答' }}</span>
                    {{
                      vo.questionDetail.doRecord.doStatus && vo.questionDetail.doRecord.answer
                        ? `(${resultType[vo.questionDetail.doRecord.doStatus]})`
                        : null
                    }}</span
                  >
                  <template v-else>
                    <MonacoEditor
                      style="min-height: 200px"
                      :editorId="vo.questionId + vIndex"
                      :value="vo.questionDetail.doRecord.answer"
                      :readOnly="true"
                      :language="''"
                    />
                  </template>
                </div>
                <div
                  v-if="vo.questionDetail.doRecord.answer !== undefined"
                  style="color: #06C281;margin-top: 12px"
                  class="analysis_style yt-flex-layout"
                >
                  标准答案:<span style="margin: 0 0 0 3px" v-html="vo.questionDetail.answer" />
                </div>
              </div>

              <!--         解析  analysis     -->
              <div v-if="vo.questionDetail.analysis" class="analysis_style yt-flex-layout" style="margin-top: 12px">
                <span class="first-span">解析:</span>
                <span v-html="cleanWord(vo.questionDetail.analysis)" />
              </div>

              <!--         知识点  knowledgePoints     -->
              <div
                v-if="vo.questionDetail.knowledgePoints && vo.questionDetail.knowledgePoints.length"
                style="margin-top: 12px"
                class="tags_style yt-flex-layout"
              >
                <span>知识点:</span>
                <span v-for="(k, kIndex) in vo.questionDetail.knowledgePoints" :key="kIndex">{{ k.knowledgeName }}</span>
              </div>

              <!--         题目技能  abilityList     -->
              <div
                v-if="vo.questionDetail.abilityList && vo.questionDetail.abilityList.length"
                style="margin-top: 12px"
                class="tags_style yt-flex-layout"
              >
                <span>技能:</span>
                <span v-for="(k, kIndex) in vo.questionDetail.abilityList" :key="kIndex">{{ k.abilityName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="exam_right">
        <!--        右侧筛选-->
        <div class="filter_header yt-flex-layout">
          <p :class="!filterType ? 'selected_filter' : ''" class="filter_default" @click="filterType = 0">题目类型</p>
          <p :class="filterType ? 'selected_filter' : ''" class="filter_default" @click="filterType = 1">题目技能</p>
        </div>
        <!--   题目类型筛选     -->
        <div v-show="!filterType">
          <div class="row_one--type yt-flex-layout" v-for="(q, qIndex) in questionList" :key="qIndex">
            <div class="title yt-flex-layout" v-show="q.questions.length">
              <div class="blue_tip" />
              <span>{{ q.name }}</span>
            </div>
            <div class="question_number" v-show="q.questions.length">
              <div
                @click="clickToOrder(vo)"
                :class="selectNumById === vo.questionId ? 'select_one--style' : ''"
                class="number_one"
                :style="{ color: setStatus(vo.questionDetail) }"
                v-for="(vo, vIndex) in q.questions"
                :key="vIndex"
              >
                {{ vIndex < 9 ? `0${vIndex + 1}` : vIndex + 1 }}
              </div>
            </div>
          </div>
        </div>
        <!--   题目技能筛选     -->
        <div v-show="filterType">
          <el-tree class="ability_tree--style" :highlight-current="true" node-key="id" :data="abilityTree" @node-click="handleNodeClick">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <span v-if="data.id !== 0">{{ data.questionCount }}</span>
            </span>
          </el-tree>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import practiceApi from '@api/practice'
import MonacoEditor from '@components/common/MonacoEditor'
export default {
  name: 'PracticeDetail',
  components: { MonacoEditor, YTIcon },
  data() {
    return {
      resultType: ['正确', '错误', '未做', '不全对', '待批阅'],
      formatNumber: ['一', '二', '三'],
      referenceTimes: 0,
      filterType: 0,
      isWrong: false, // 是否只看错题
      isReset: false, // 重置筛选
      selectNumById: null,
      paperName: null,
      selectRow: 0,
      abilityTree: [],
      infoList: [
        {
          name: '姓名',
          key: 'userName',
          icon: 'user-fill',
          color: '#7CAEEF',
          value: null,
          company: ''
        },
        {
          name: '考试时间',
          key: 'startTime',
          icon: 'time',
          color: '#7CAEEF',
          value: null,
          company: ''
        },
        {
          name: '结束时间',
          key: 'submitTime',
          icon: 'time',
          color: '#7CAEEF',
          value: null,
          company: ''
        },
        {
          name: '用时',
          key: 'duration',
          color: '#FFBB00',
          icon: 'shijian2',
          value: 0,
          company: '分钟'
        },
        {
          name: '得分',
          key: 'score',
          color: '#FF5050',
          icon: 'pingjunfenshu',
          value: 0,
          company: '分'
        }
      ],
      questionList: [],
      copyQuestion: [], // 题目信息拷贝  深拷贝
      sendExamInfo: null
    }
  },
  computed: {
    setStatus() {
      return data => {
        if (data.doRecord) {
          if (data.doRecord.doStatus && !this.isWrong) {
            return '#DA2E2E'
          }
        }
      }
    }
  },
  mounted() {
    let { userId, practiceId, answerPaperId } = this.$handleParams('PracticeDetail')
    this.getUserAnswerPapers(userId, practiceId)
    this.getAnswerDetails(answerPaperId)
    this.getAnswerAbility(answerPaperId)
  },
  methods: {
    //获取用户某次练习的答卷列表
    getUserAnswerPapers(userId, practiceId) {
      practiceApi.getUserAnswerPapers(userId, practiceId).then(res => {
        if (res.code === 0) {
          for (let infoListElement of this.infoList) {
            infoListElement.value = res.res[0][infoListElement.key]
          }
        }
      })
    },
    //查看练习答卷详情
    getAnswerDetails(answerPaperId) {
      practiceApi.getAnswerDetails(answerPaperId).then(res => {
        if (res.code === 0) {
          this.setList(res.res)
        }
      })
    },
    //查看练习技能
    getAnswerAbility(answerPaperId) {
      practiceApi.getAnswerAbility(answerPaperId).then(res => {
        if (res.code === 0) {
          this.abilityTree = res.res.map(e => {
            return {
              id: e.abilityId,
              label: e.abilityName,
              questionCount: e.questionCount || 0,
              children:
                e.examAbilityList && e.examAbilityList.length
                  ? e.examAbilityList.map(vo => {
                      return {
                        id: vo.abilityId,
                        label: vo.abilityName,
                        questionCount: vo.questionCount || 0
                      }
                    })
                  : []
            }
          })
          this.abilityTree.unshift({
            id: 0,
            label: '所有技能',
            questionCount: 0,
            children: []
          })
        }
      })
    },
    //题目组合
    setList(data) {
      for (const datum of data) {
        for (const question of datum.questions) {
          if (question.questionDetail.options) {
            let correct = []
            for (let i = 0; i < question.questionDetail.options.length; i++) {
              if (question.questionDetail.options[i].correct) {
                correct.push(this.$getChoiceIndex(i))
              }
            }
            question.questionDetail['answer'] = correct.toString()
          }
          if (!question.questionDetail.doRecord) {
            question.questionDetail['doRecord'] = []
            question.questionDetail['doRecord']['answer'] = ''
          }
          switch (question.questionDetail['questionType']) {
            case 0:
              const doRecordAnswer = question.questionDetail.doRecord.answer
              const questionAnswer = question.questionDetail['answer']
              question.questionDetail.doRecord.answer = doRecordAnswer === 'true' ? '正确' : '错误'
              question.questionDetail['answer'] = questionAnswer === 'true' ? '正确' : '错误'
              break
            case 1:
              const doRecord = question.questionDetail.doRecord.answer ? question.questionDetail.doRecord.answer : ''
              let findDoRecord = question.questionDetail.options.findIndex(o => o.content === doRecord) // 查找考生的答案
              let findAnswer = question.questionDetail.options.findIndex(o => o.correct) // 查找正确答案
              question.questionDetail.doRecord.answer = findDoRecord !== -1 ? this.$getChoiceIndex(findDoRecord) : null
              question.questionDetail['answer'] = findAnswer !== -1 ? this.$getChoiceIndex(findAnswer) : null
              break
            case 2:
              // 多选题
              let arr = question.questionDetail.doRecord.answer ? JSON.parse(question.questionDetail.doRecord.answer) : []
              question.questionDetail['answer'] = []
              arr.map(arrData => {
                let findAnswer = question.questionDetail.options.findIndex(o => o.content === arrData) // 查找考生的答案
                if (findAnswer !== -1) {
                  arrData = this.$getChoiceIndex(findAnswer)
                }
                return arrData
              })
              // 查找正确答案
              question.questionDetail['answer'] = question.questionDetail.options
                .map((op, oIndex) => {
                  if (op.correct) {
                    return this.$getChoiceIndex(oIndex)
                  }
                })
                .filter(item => item)
              question.questionDetail.doRecord.answer = this.$replaceHtml(arr.sort().join('、'))
              question.questionDetail['answer'] = Array.from(new Set(question.questionDetail['answer'].sort())).join('、')
              break
            case 3:
              // 填空题
              // {"__1__":["叽叽"],"__2__":["jiji"]} 填空题考生答案的格式
              // 标准答案的格式是array
              question.questionDetail.doRecord.answer = question.questionDetail.doRecord.answer
                ? JSON.parse(question.questionDetail.doRecord.answer)
                : ''
              let myAnswer = []
              if (question.questionDetail.doRecord.answer) {
                for (const vos of Object.keys(question.questionDetail.doRecord.answer)) {
                  myAnswer.push(question.questionDetail.doRecord.answer[vos][0])
                }
                question.questionDetail.doRecord.answer = myAnswer.toString()
              }
              let answer = []
              answer = question.questionDetail['blanks'].map(b => {
                return b.blankAnswer[0]
              })
              question.questionDetail['answer'] = answer.toString()
              break
            case 5:
              // 代码题
              // 考生的答案是JSON格式
              if (question.questionDetail.doRecord.answer) {
                let code = JSON.parse(question.questionDetail.doRecord.answer)
                question.questionDetail.doRecord.answer = code.answer
              }
              break
          }
        }
      }
      this.copyQuestion = this.questionList = data
    },

    getExamNum(index) {
      // 获取考试次数
      let baseNum = ['一', '二', '三', '四', '五', '六', '七', '八', '九']
      if (index < 10) {
        return baseNum[index - 1]
      }
    },
    handleNodeClick(row) {
      // 树  点击事件
      let a = []
      if (row.id === 0) return this.filterQuestion(2, [])
      if (row.children && row.children.length) {
        a = row.children.map(c => {
          return c.id // push click parent tree data
        })
      } else {
        a.push(row.id) // push children tree data
      }
      this.filterQuestion(1, a)
    },
    filterQuestion(order, arr) {
      // 题目筛选
      //  order  0 只看错题
      //  order  1 技能筛选
      //  order  2 重置筛选
      // 深拷贝题目信息  避免修改污染所有数据
      this.questionList = JSON.parse(JSON.stringify(this.copyQuestion))
      if (order === 2) {
        this.isWrong = false
        return
      } else {
        this.isReset = false
      }
      if (order === 0 && this.isWrong) {
        for (const orderElement of this.questionList) {
          orderElement.questions = orderElement.questions.filter(vo => {
            if (vo.questionDetail.doRecord) {
              return vo.questionDetail.doRecord.doStatus
            }
          })
        }
        this.questionList = this.questionList.filter(list => list.questions && list.questions.length)
      }
      if (order === 1) {
        this.isWrong = false
        for (const questionElement of this.questionList) {
          questionElement.questions = questionElement.questions
            .map(vo => {
              let answer = []
              if (vo.questionDetail.abilityList && vo.questionDetail.abilityList.length) {
                for (const arrElement of arr) {
                  let findAnswer = vo.questionDetail.abilityList.findIndex(ab => arrElement === ab.abilityId)
                  if (findAnswer !== -1) {
                    answer = vo
                  }
                }
              }
              return answer // return vo
            })
            .filter(vo => vo) // delete undefined
        }
        this.questionList = this.questionList.filter(list => list.questions && list.questions.length) // filter questionVOS = []
      }
    },
    clickToOrder(row) {
      // 点击题号跳转指定题目位置
      this.selectNumById = row.questionId
      document.getElementById(`num-${row.questionId}`).scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      })
    },
    getOneExamInfo(index) {
      // 获取某场考试的信息
      this.selectRow = index
      // 获取第N次考试的信息
      this.sortInfo(index)
    },
    sortInfo(index) {
      let firstInfo = this.sendExamInfo[index]
      this.infoList.forEach(l => {
        l.value = firstInfo[l.key]
      })
      this.getExamContentInfo(firstInfo['answerPaperId'])
      // 获取技能数数据
      this.getAbilityTree(firstInfo['answerPaperId'])
    }
  }
}
</script>

<style lang="less" scoped>
.exam_detail--style {
  height: 100%;
  .font(12px, Regular, #000);
  .yt-container {
    height: calc(100% - 20px);
  }
  .exam_left {
    overflow: hidden;
    width: calc(100% - 260px);
    padding: 20px 0;
    .exam_header {
      padding: 0 20px;
      justify-content: space-between;
      align-items: center;
      .exam_name {
        margin-right: 20px;
      }
      .span_default {
        border: 1px solid #d9d9d9;
        position: relative;
        img {
          position: absolute;
          right: -1px;
          bottom: -1px;
        }
      }
      .span_unselected {
        cursor: pointer;
        align-items: center;
        .font(12px, Regular, #999);
        padding: 0 15px;
        border-radius: 4px;
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
      .selected_exam--info {
        border: 1px solid #448bff;
        color: #448bff;
      }
    }
    .exam_info {
      padding: 0 20px;
      margin: 20px 0;
      .info_one--style {
        color: #666;
        align-items: center;
        svg {
          margin-right: 6px;
          .wh(20px, 20px);
        }
        &:not(:last-child) {
          margin-right: 40px;
        }
      }
    }
    .exam_question {
      height: calc(100% - 77px);
      overflow-y: auto;
      .big-title {
        color: #282c3d;
        padding: 0 20px;
        align-items: center;
        height: 50px;
        background: #f0f0f0;
        span {
          margin: 0 5px;
          font-size: 16px;
          color: #448bff;
        }
      }
      .question_info {
        padding: 20px 20px 23px;
        ::v-deep .ivu-radio-wrapper {
          margin-right: 40px;
        }
        &:last-child {
          padding-bottom: 20px !important;
        }
        .stem {
          font-size: 12px;
          overflow: hidden;
          ::v-deep img {
            max-width: calc(100% - 30px) !important;
            height: auto;
          }
        }
        .analysis_style {
          white-space: nowrap;
          overflow: hidden;
          .first-span {
            margin-right: 10px;
          }
          span:last-child {
            white-space: normal;
          }
        }
        .tags_style {
          display: flex;
          white-space: nowrap;
          align-items: center;
          flex-wrap: wrap;
          span {
            margin-bottom: 10px;
            &:first-child {
              margin-right: 10px;
            }
            &:not(:first-child) {
              margin-right: 20px;
              padding: 3px 6px;
              background: #edf4ff;
              color: #448bff;
            }
          }
        }
      }
    }
  }
  .exam_right {
    width: 260px;
    margin-left: 10px;
    padding: 20px 10px;
    overflow-y: auto;
    .filter_header {
      height: 20px;
      border-radius: 36px;
      background-color: #ececec;
      .filter_default {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        .font(12px, Regular, #999);
      }
      .selected_filter {
        color: #fff;
        background-color: #438bff;
        border-radius: 36px;
      }
    }
    .row_one--type {
      flex-direction: column;
      margin-top: 20px;
      .title {
        align-items: center;
      }
      .blue_tip {
        .wh(3px, 10px);
        background-color: #438bff;
        margin-right: 3px;
      }
      .question_number {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        .number_one {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          font-size: 12px;
          border: 1px solid #d9d9d9;
          .wh(24px, 24px);
          border-radius: 3px;
          cursor: pointer;
          &:hover {
            border: 1px solid #438bff;
            background-color: #438bff;
            color: #fff;
          }
        }
        .select_one--style {
          border: 1px solid #438bff;
          background-color: #438bff;
          color: #fff;
        }
      }
    }
  }
  .exam_left,
  .exam_right {
    background: #fff;
    border-radius: 6px 0px 0px 6px;
  }
}
::v-deep {
  .el-radio {
    margin-right: 50px;
  }
  .el-radio__inner {
    background: #f0f2f3;
    border-color: #bfc1c6;
  }
  .el-radio__label {
    font-size: @small;
    color: #000000 !important;
  }
  .is-checked {
    .el-radio__inner {
      background-color: #448bff;
      &:after {
        display: none;
      }
    }
  }
  .ability_tree--style {
    .font(12px, Regular, #000);
    margin-top: 12px;
    .custom-tree-node {
      flex: 1;
      display: flex;
      justify-content: space-between;
    }
    .el-tree-node__expand-icon {
      color: #282c3d;
    }
    .el-tree-node__content {
      padding-right: 10px;
      display: flex;
      height: 29px !important;
      .custom-tree-node {
        span:first-child {
          font-weight: bold;
        }
      }
    }
    .el-tree-node__children {
      .is-current {
        .el-tree-node__expand-icon {
          color: #fff;
        }
        background-color: #edf4ff;
        color: #448bff;
      }
      .el-tree-node {
        height: 29px !important;
      }
      .el-tree-node__expand-icon {
        color: #fff;
      }
      .custom-tree-node {
        span:first-child {
          font-weight: 400;
        }
      }
    }
  }
  .Bash {
    xmp {
      white-space: normal;
    }
  }
}
</style>
