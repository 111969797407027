import { axios } from '@/api/index'

const baseUrl = '/course/api/v1/practice'
const practiceUrl = '/course/api/v2/practice'
const examPracticeUrl = '/exam/api/v2/practice'

export default {
  //查看练习技能
  getAnswerAbility(answerPaperId) {
    return axios.get(`${examPracticeUrl}/answer/ability?answerPaperId=${answerPaperId}`)
  },
  //查看练习答卷详情
  getAnswerDetails(answerPaperId) {
    return axios.get(`${examPracticeUrl}/answer/details?answerPaperId=${answerPaperId}`)
  },
  //获取用户某次练习的答卷列表
  getUserAnswerPapers(userId, practiceId) {
    return axios.get(`${examPracticeUrl}/user/answer-papers?userId=${userId}&practiceId=${practiceId}`)
  },
  //获取练习知识点个人掌握率
  getKnowledPersonal(page, size, practiceId, knowledgeId) {
    return axios.get(
      `${examPracticeUrl}/get/knowledge/personal/grasp/info?sort=asc&page=${page}&size=${size}&practiceId=${practiceId}&knowledgeId=${knowledgeId}`
    )
  },
  //获取练习人员排名情况
  getPracticeRanking(page, size, practiceId) {
    return axios.get(`${examPracticeUrl}/get/ranking?sort=asc&page=${page}&size=${size}&practiceId=${practiceId}`)
  },
  //获取练习知识点掌握情况
  getGraspInfo(practiceId) {
    return axios.get(`${examPracticeUrl}/get/knowledge/grasp/info?practiceId=${practiceId}`)
  },
  //获取练习分数分布
  getDistributeScore(practiceId) {
    return axios.get(`${examPracticeUrl}/get/score/distribute?practiceId=${practiceId}`)
  },
  //获取练习知识点题型数量
  getknowledgeQuestion(practiceId) {
    return axios.get(`${examPracticeUrl}/get/knowledge/question/count?practiceId=${practiceId}`)
  },
  //练习个人结果分析
  getResultAnalysis(answerPaperId) {
    return axios.get(`${examPracticeUrl}/get/result/analysis?answerPaperId=${answerPaperId}`)
  },
  //获取个人题型得分情况
  getScoreInfo(answerPaperId) {
    return axios.get(`${examPracticeUrl}/get/type/score/info?answerPaperId=${answerPaperId}`)
  },
  //获取练习知识点得分情况
  getKnowledgeInfo(practiceId) {
    return axios.get(`${examPracticeUrl}/get/knowledge/score/info?practiceId=${practiceId}`)
  },
  //个人练习基本信息
  getPersonalInfo(answerPaperId) {
    return axios.get(`${examPracticeUrl}/get/personal/info?answerPaperId=${answerPaperId}`)
  },
  //一场练习所有参加的人员
  getAllPracticeUser(page, size, payload) {
    return axios.post(`${examPracticeUrl}/get/all/practice/user?sort=asc&page=${page}&size=${size}`, payload)
  },
  //一场的练习统计
  getPracticeStatistics(payload) {
    return axios.get(`${examPracticeUrl}/get/statistics?practiceId=${payload}`)
  },
  //获取练习信息
  getPracticeInfo(payload) {
    return axios.get(`${examPracticeUrl}/get/info?practiceId=${payload}`)
  },
  //修改手动练习
  modifyPracticeManual(payload) {
    return axios.post(`${practiceUrl}/update/manual`, payload)
  },
  //获取手动练习信息
  getManualInfo(payload) {
    return axios.get(`${practiceUrl}/get/manual/info?practiceId=${payload}`)
  },
  //修改随机练习
  modifyPracticeRandom(payload) {
    return axios.post(`${practiceUrl}/update/random`, payload)
  },
  //获取随机练习信息
  getRandomInfo(payload) {
    return axios.get(`${practiceUrl}/get/random/info?practiceId=${payload}`)
  },
  //新增随机练习
  getPracticeRandom(payload) {
    return axios.post(`${practiceUrl}/add/random`, payload)
  },
  //新增手动练习
  addPracticeManual(payload) {
    return axios.post(`${practiceUrl}/add/manual`, payload)
  },
  //获取章节下所有小节的练习
  getAllPractice(payload) {
    return axios.get(`${baseUrl}/get/chapter/all/practice?chapterId=${payload}`)
  },
  deletePractice(practiceId, sectionId) {
    return axios.post(`${baseUrl}/delete/practice/section?practiceId=${practiceId}&sectionId=${sectionId}`)
  }
}
